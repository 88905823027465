import React from "react"
import { StaticQuery, graphql } from "gatsby"

import Layout from "../../../components/layout"
import Seo from "../../../components/seo"
import CaseStudyHeader from "../../../components/pages/work/case-studies/CaseStudyHeader"
import CaseStudyConsultation from "../../../components/pages/work/case-studies/CaseStudyConsultation"
import CaseStudyUserStories from "../../../components/pages/work/case-studies/CaseStudyUserStories"
import CaseStudyWireframes from "../../../components/pages/work/case-studies/CaseStudyWireframes"
import CaseStudyIterations from "../../../components/pages/work/case-studies/CaseStudyIterations"
import CaseStudyTechnologies from "../../../components/pages/work/case-studies/CaseStudyTechnologies"
import CaseStudyResults from "../../../components/pages/work/case-studies/CaseStudyResults"
import Contact from "../../../components/pages/index/Contact"
import CaseStudyChallenges from "../../../components/pages/work/case-studies/CaseStudyChallenges"

const WFHListerPage = ({ pageContext, location }) => {
  const consultationDetails = {
    company: "WFH Lister",
    location: "Toronto, Canada",
    industry: "Job Listings",
    developmentType: "Web Application",
    scope: "Design and Development",
    businessObjectives:
      "To connect companies with Work From Home jobs to highly qualified candidates. WFH-Lister wants to be the premier WFH Lister with the most WFH jobs in the job listing industry. This will require a minimum of 1000 active job listings in the first year of operation.",
    desiredOutcomes:
      "To provide an easy to use platform for companies and candidates to meet. Finding a job or finding a candidate should be achievable within 10 seconds of searching. Posting along with payment should take no longer than 2 minutes.",
    targetMarket: "Developers, Designers, Project Managers",
    competition: "Monster",
    competitiveAdvantage: "WFH Lister is solely focused on Work From Home jobs",
    timeFrame: "6 Months",
  }

  const userStories = {
    mustHaves: [
      "A long form front page that shows some of the highlights from the site.",
      "Featured Jobs section on front page",
      "Featured Candidates section on front page",
      "Featured Companies section on front page",
      "Job Search section on front page",
      "Testimonials section on front page",
      "Job Search page with detailed filters",
      "Candidate Search page with detailed filters",
      "Candidate listing pages",
      "Job listing pages",
      "Blog to provide career advice",
      "Contact Us page",
    ],
    niceToHaves: [
      "A direct messaging system between employers and candidates",
      "A favorite system for jobs and candidates",
      "Admin panel to manage jobs, companies and candidates.",
      "Social Media sharing buttons on jobs and candidates",
      "Multiple language support.",
      "Live chat support system",
      "Option for job listing and candidate listing to upload video",
      "Pay to be featured on the front page",
    ],
    maybeLater: [
      "Banner advertising in unobtrusive areas",
      "Integrated video interview system",
      "Company profile section with Linkedin API integration",
      "Affiliate program",
      "Infinite scroll on listing instead of pagination.",
      "Every candidate that meets criteria gets auto-interviewed",
    ],
  }

  const iterations = {
    iteration1: {
      stories: [
        "Design and development environment setup",
        "Asset collection and review",
        "Wireframe review",
      ],
      weeks: "Week 1",
    },
    iteration2: {
      stories: [
        "Header design and development",
        "Footer design and development",
        "Navbar design and development",
      ],
      weeks: "Week 2",
    },
    iteration3: {
      stories: [
        "Database development for user data",
        "Filesystem setup for candidate and company",
        "API endpoint development for signup and login",
        "Signup page design and development",
        "Login modal creation",
      ],
      weeks: "Week 3-4",
    },
    iteration4: {
      stories: [
        "Profile page design and development",
        "API endpoint development for viewing and editing user data",
      ],
      weeks: "Week 5",
    },
    iteration5: {
      stories: [
        "Database development for jobs",
        "Filesystem setup for alternate logos",
        "API endpoint development for job creation and view",
        "Job creation page design and development",
        "In profile view added",
      ],
      weeks: "Week 6-7",
    },
    iteration6: {
      stories: [
        "Database development for candidates",
        "API endpoint development for candidate creation and view",
        "Candidate creation page design and development",
        "In profile view added",
      ],
      weeks: "Week 8-9",
    },
    iteration7: {
      stories: [
        "Paypal integration for publishing job posting",
        "Stripe integration for publishing job posting",
      ],
      weeks: "Week 10",
    },
    iteration8: {
      stories: [
        "Design and develop Job Search page",
        "API endpoint development for filter parameters",
        "Add sorting option for job listings",
        "API endpoint development for favorites",
        "Add favorites to profile view",
      ],
      weeks: "Week 11-12",
    },
    iteration9: {
      stories: [
        "Job listing page design and development",
        "API endpoint development for details and summary",
        "Email API development for job application",
      ],
      weeks: "Week 13-14",
    },
    iteration10: {
      stories: [
        "Design and develop Candidate Search page",
        "API endpoint development for filter parameters",
        "Add sorting option for candidate listings",
        "API endpoint development for favorites",
        "Add favorites to profile view",
      ],
      weeks: "Week 15-16",
    },
    iteration11: {
      stories: [
        "Candidate listing page design and development",
        "API endpoint development for details and summary",
        "Email API development for candidate contact",
      ],
      weeks: "Week 17-18",
    },
    iteration12: {
      stories: [
        "Design and develop Contact Page",
        "Email API development for contact page",
      ],
      weeks: "Week 19",
    },
    iteration13: {
      stories: [
        "Design and develop home page",
        "Re-use APIs to build sections for search, featured jobs & candidates",
        "API endpoint development for Top Companies",
        "Testimonials hard coded",
      ],
      weeks: "Week 20-21",
    },
    iteration14: {
      stories: ["Design and develop blog", "Use Wordpress as backend API"],
      weeks: "Week 22-23",
    },
    iteration15: {
      stories: [
        "Release",
        "Emergency support standby as website is tested with live traffic",
      ],
      weeks: "Week 24",
    },
  }

  const technologies = {
    fullStack: true,
    icons: [
      "HTML5",
      "CSS3",
      "Sass",
      "Bootstrap",
      "JavaScript",
      "React",
      "Node",
      "MongoDB",
      "Jest",
      "Git",
      "Heroku",
    ],
  }

  const challenges = {
    challenge1: {
      challenge:
        "WFH Lister was a large ambitious project with very little time to first release.",
      win: (
        <>
          <p>
            The Tactic Apps team sees this quite often, too many stories and not
            enough time to complete them all. However, WFH Lister's "Must Have"
            list consisted of mostly large end-to-end features that put us on a
            tight release schedule.
          </p>
          <p>
            Using Agile methodologies, the Tactic Apps team decided to operate
            on a 2 week iteration schedule as most of the stories were too large
            to iterate over a single week.
          </p>
          <p className="mb-0">
            In collaboration with the WFH Lister team, all their stories where
            sized, prioritized and iterated to fit right into their 6 month
            deadline. Using this Agile approach, the project was completed on
            time and within budget.
          </p>
        </>
      ),
    },
    challenge2: {
      challenge:
        "There was not enough time to build a blogging system from scratch.",
      win: (
        <>
          <p>
            After the Tactic Apps team iterated through all the "Must Jave"
            stories, we realized that there would only be 2 weeks remaining to
            build the frontend and backend for the blog. Unfortunately this was
            not possible if starting from scratch.
          </p>
          <p className="mb-0">
            We decided to have a strategy session with the WFH Lister team and
            came to the conclusion that an pre-built blogging system was the
            best way forward. Together we chose Wordpress as the backend of
            choice so the Tactic Apps team only have to build out the frontend.
          </p>
        </>
      ),
    },
    challenge3: {
      challenge:
        "The WFH Lister team wanted to make some changes in scope half way through the project.",
      win: (
        <>
          <p>
            Making changes during a project is a common request and something
            that is welcomed by the Tactic Apps team. However, it is often a
            difficult decision because the change will either cause the removal
            of another iteration or a change in time, budget or both.
          </p>
          <p>
            In particular, the WFH Lister team realized they needed an admin
            panel at launch to manage all their users. This was a problem as it
            required a full 2 week iteration cycle.
          </p>
          <p className="mb-0">
            To avoid impacting the current release schedule's time and budget,
            the Tactic Apps and WFH Lister teams decided to develop the admin
            panel directly after release. This allowed WFH Lister to launch on
            time and gather more budget to develop the admin panel with minimal
            impact.
          </p>
        </>
      ),
    },
    challenge4: {
      challenge:
        "3 Months into the project, Tactic Apps' backend developer fell ill and had to go on sick leave.",
      win: (
        <>
          <p>
            In web development it can be catastrophic to project deadlines when
            a key member of the development team is forced to exit a project.
            Their replacement needs to get up to speed in hours, not days.
          </p>
          <p>
            At Tactic Apps we pride ourselves on the custom Agile framework
            we've built. It not only helps our customers stay on time and
            budget, but also helps our team when these types of problems arise.
          </p>
          <p className="mb-0">
            Thanks to our end to end, fully tested iterations, our new backend
            developer was able to plug right in and was up to speed in a couple
            of hours. This kept the WFH Lister project on track.
          </p>
        </>
      ),
    },
    challenge5: {
      challenge:
        "The WFH Lister team decided last minute to change the deployment from Docker+AWS to Heroku.",
      win: (
        <>
          <p>
            A day before release, the WFH Lister team asked us if it was
            possible to move the deployment from Docker+AWS to Heroku due to
            potential cost savings within their first year of business.
          </p>
          <p className="mb-0">
            The Tactic Apps team strategized internally and came to the
            conclusion that this was possible. Our team worked late into the
            night and pulled this off just in time for release.
          </p>
        </>
      ),
    },
  }

  const caseStudyData = {
    numBoxes: {
      developers: "3",
      hourly: "145",
      time: "6",
    },
  }

  const resultsImageNames = {
    results1: "Home Page 1",
    results2: "Home Page 2",
    results3: "Home Page 3",
    results4: "Find Jobs",
    results5: "Job Listing",
    results6: "Find Candidates",
    results7: "Candidate Listing",
    results8: "Blog",
    results9: "Blog Entry 1",
    results10: "Blog Entry 2",
    results11: "Contact",
  }

  return (
    <StaticQuery
      query={graphql`
        {
          heroLg: file(
            relativePath: { eq: "work/web/wfh-lister/wfh-lister-hero.png" }
          ) {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
          heroSm: file(
            relativePath: {
              eq: "work/web/wfh-lister/wfh-lister-hero-small.png"
            }
          ) {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
          deviceDisplay: file(
            relativePath: { eq: "work/web/wfh-lister/wfh-lister-devices.png" }
          ) {
            childImageSharp {
              gatsbyImageData(width: 1200, layout: CONSTRAINED)
            }
          }
          wireframe1: file(
            relativePath: {
              eq: "work/web/wfh-lister/wireframes/wfh_lister_landing_page.png"
            }
          ) {
            childImageSharp {
              gatsbyImageData(width: 1200, layout: CONSTRAINED)
            }
          }
          wireframe2: file(
            relativePath: {
              eq: "work/web/wfh-lister/wireframes/wfh_lister_landing_page_2.png"
            }
          ) {
            childImageSharp {
              gatsbyImageData(width: 1200, layout: CONSTRAINED)
            }
          }
          wireframe3: file(
            relativePath: {
              eq: "work/web/wfh-lister/wireframes/wfh_lister_landing_page_3.png"
            }
          ) {
            childImageSharp {
              gatsbyImageData(width: 1200, layout: CONSTRAINED)
            }
          }
          wireframe4: file(
            relativePath: {
              eq: "work/web/wfh-lister/wireframes/wfh_lister_landing_page_4.png"
            }
          ) {
            childImageSharp {
              gatsbyImageData(width: 1200, layout: CONSTRAINED)
            }
          }
          wireframe5: file(
            relativePath: {
              eq: "work/web/wfh-lister/wireframes/wfh_lister_find_jobs.png"
            }
          ) {
            childImageSharp {
              gatsbyImageData(width: 1200, layout: CONSTRAINED)
            }
          }
          wireframe6: file(
            relativePath: {
              eq: "work/web/wfh-lister/wireframes/wfh_lister_job_listing_1.png"
            }
          ) {
            childImageSharp {
              gatsbyImageData(width: 1200, layout: CONSTRAINED)
            }
          }
          wireframe7: file(
            relativePath: {
              eq: "work/web/wfh-lister/wireframes/wfh_lister_job_listing_2.png"
            }
          ) {
            childImageSharp {
              gatsbyImageData(width: 1200, layout: CONSTRAINED)
            }
          }
          wireframe8: file(
            relativePath: {
              eq: "work/web/wfh-lister/wireframes/wfh_lister_find_candidates.png"
            }
          ) {
            childImageSharp {
              gatsbyImageData(width: 1200, layout: CONSTRAINED)
            }
          }
          wireframe9: file(
            relativePath: {
              eq: "work/web/wfh-lister/wireframes/wfh_lister_candidate_listing_1.png"
            }
          ) {
            childImageSharp {
              gatsbyImageData(width: 1200, layout: CONSTRAINED)
            }
          }
          wireframe10: file(
            relativePath: {
              eq: "work/web/wfh-lister/wireframes/wfh_lister_candidate_listing_2.png"
            }
          ) {
            childImageSharp {
              gatsbyImageData(width: 1200, layout: CONSTRAINED)
            }
          }
          wireframe11: file(
            relativePath: {
              eq: "work/web/wfh-lister/wireframes/wfh_lister_blog_1.png"
            }
          ) {
            childImageSharp {
              gatsbyImageData(width: 1200, layout: CONSTRAINED)
            }
          }
          wireframe12: file(
            relativePath: {
              eq: "work/web/wfh-lister/wireframes/wfh_lister_blog_2.png"
            }
          ) {
            childImageSharp {
              gatsbyImageData(width: 1200, layout: CONSTRAINED)
            }
          }
          wireframe13: file(
            relativePath: {
              eq: "work/web/wfh-lister/wireframes/wfh_lister_blog_post_1.png"
            }
          ) {
            childImageSharp {
              gatsbyImageData(width: 1200, layout: CONSTRAINED)
            }
          }
          wireframe14: file(
            relativePath: {
              eq: "work/web/wfh-lister/wireframes/wfh_lister_blog_post_2.png"
            }
          ) {
            childImageSharp {
              gatsbyImageData(width: 1200, layout: CONSTRAINED)
            }
          }
          wireframe15: file(
            relativePath: {
              eq: "work/web/wfh-lister/wireframes/wfh_lister_blog_post_3.png"
            }
          ) {
            childImageSharp {
              gatsbyImageData(width: 1200, layout: CONSTRAINED)
            }
          }
          wireframe16: file(
            relativePath: {
              eq: "work/web/wfh-lister/wireframes/wfh_lister_contact.png"
            }
          ) {
            childImageSharp {
              gatsbyImageData(width: 1200, layout: CONSTRAINED)
            }
          }
          results1: file(
            relativePath: { eq: "work/web/wfh-lister/wfh-lister-home1.jpg" }
          ) {
            childImageSharp {
              gatsbyImageData(width: 800, layout: CONSTRAINED)
            }
          }
          results2: file(
            relativePath: { eq: "work/web/wfh-lister/wfh-lister-home2.jpg" }
          ) {
            childImageSharp {
              gatsbyImageData(width: 800, layout: CONSTRAINED)
            }
          }
          results3: file(
            relativePath: { eq: "work/web/wfh-lister/wfh-lister-home3.jpg" }
          ) {
            childImageSharp {
              gatsbyImageData(width: 800, layout: CONSTRAINED)
            }
          }
          results4: file(
            relativePath: { eq: "work/web/wfh-lister/wfh-lister-find-jobs.jpg" }
          ) {
            childImageSharp {
              gatsbyImageData(width: 800, layout: CONSTRAINED)
            }
          }
          results5: file(
            relativePath: {
              eq: "work/web/wfh-lister/wfh-lister-job-listing.jpg"
            }
          ) {
            childImageSharp {
              gatsbyImageData(width: 800, layout: CONSTRAINED)
            }
          }
          results6: file(
            relativePath: {
              eq: "work/web/wfh-lister/wfh-lister-find-candidates.jpg"
            }
          ) {
            childImageSharp {
              gatsbyImageData(width: 800, layout: CONSTRAINED)
            }
          }
          results7: file(
            relativePath: { eq: "work/web/wfh-lister/wfh-lister-candidate.jpg" }
          ) {
            childImageSharp {
              gatsbyImageData(width: 800, layout: CONSTRAINED)
            }
          }
          results8: file(
            relativePath: { eq: "work/web/wfh-lister/wfh-lister-blog.jpg" }
          ) {
            childImageSharp {
              gatsbyImageData(width: 800, layout: CONSTRAINED)
            }
          }
          results9: file(
            relativePath: {
              eq: "work/web/wfh-lister/wfh-lister-blog-entry1.jpg"
            }
          ) {
            childImageSharp {
              gatsbyImageData(width: 800, layout: CONSTRAINED)
            }
          }
          results10: file(
            relativePath: {
              eq: "work/web/wfh-lister/wfh-lister-blog-entry2.jpg"
            }
          ) {
            childImageSharp {
              gatsbyImageData(width: 800, layout: CONSTRAINED)
            }
          }
          results11: file(
            relativePath: { eq: "work/web/wfh-lister/wfh-lister-contact.jpg" }
          ) {
            childImageSharp {
              gatsbyImageData(width: 800, layout: CONSTRAINED)
            }
          }
        }
      `}
      render={(data) => (
        <Layout>
          <Seo
            title="WFH Lister Case Study"
            keywords={[`WFH Lister`]}
            description="WFH Lister connects job seekers to companies that have remote work available. This case study showcases our fullstack web application capabilities."
          />
          <CaseStudyHeader
            imageLg={data.heroLg.childImageSharp.gatsbyImageData}
            imageSm={data.heroSm.childImageSharp.gatsbyImageData}
            title="Web Application Case study"
            subTitle="WFH Lister"
            overlay="light"
            pageContext={pageContext}
            location={location}
            crumbLabel="WFH Lister"
          />
          <main>
            <CaseStudyConsultation
              consultationDetails={consultationDetails}
              deviceImage={data.deviceDisplay}
            />
            <CaseStudyUserStories
              userStories={userStories}
              companyName={consultationDetails.company}
            />
            <CaseStudyWireframes
              wireframeImages={data}
              companyName={consultationDetails.company}
            />
            <CaseStudyIterations
              iterationData={iterations}
              companyName={consultationDetails.company}
            />
            <CaseStudyTechnologies
              technologies={technologies}
              companyName={consultationDetails.company}
              developmentType={consultationDetails.developmentType}
            />
            <CaseStudyChallenges
              challenges={challenges}
              companyName={consultationDetails.company}
            />
            <CaseStudyResults
              caseStudyData={caseStudyData}
              resultsImages={data}
              resultsImageNames={resultsImageNames}
              resultsImageHeight="400px"
              companyName={consultationDetails.company}
              developmentType={consultationDetails.developmentType}
            />
            <Contact
              heading={
                <>
                  Let's build your{" "}
                  <span className="text-primary">
                    {consultationDetails.developmentType}
                  </span>{" "}
                  Together
                </>
              }
            />
          </main>
        </Layout>
      )}
    />
  )
}

export default WFHListerPage
